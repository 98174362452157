import React from 'react'
import { Accordion, Button, Drawer } from "flowbite-react";
import { useState } from "react";
import { Menu } from 'lucide-react'

const menulist = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "About",
        link: [
            {
                subname: "Our Hosts",
                sublink: "/our-hosts",
            },
        ]
    },
    {
        name: "Registration",
        link: [
            {
                subname: "Registration",
                sublink: "/registration",
            },
            {
                subname: "Accommodation",
                sublink: "/accommodation",
            },
            {
                subname: "Getting a Visa",
                sublink: "/visa",
            }
        ]
    },
    {
        name: "Program",
        link: [
            {
                subname: "Program Overview",
                sublink: "/program-overview",
            },
            {
                subname: "Our Presenters",
                sublink: "/our-presenters",
            },
        ]
    },
    {
        name: "Destination",
        link: [
            {
                subname: "Our Venue and Melbourne",
                sublink: "/our-venue-and-melbourne",
            },
            {
                subname: "Visit Victoria",
                sublink: "/visit-victoria",
            }
        ]
    },
    {
        name: "Latest News",
        link: "/latest-news",
    },
    {
        name: "Contact Us",
        link: "/contact",
    }
]

function MobileNav() {

const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  return (
    <>
        <Button onClick={() => setIsOpen(true)} className="fixed top-3 right-3 z-20 block lg:hidden mobnav"><Menu /></Button>
        <Drawer open={isOpen} onClose={handleClose} position="top" className="min-h-screen backdrop-blur-sm bg-white/80">
            <Drawer.Header title="" />

            {menulist.map((item, index) => {
                if (typeof item.link === 'string') {
                    // Simple link
                    return (
                        <a key={index} href={item.link} className="block py-2 px-4 text-xl font-bold">
                            {item.name}
                        </a>
                    );
                } else if (Array.isArray(item.link)) {
                    // Accordion for submenus
                    return (
                        <Accordion key={index} collapseAll className="accordion">
                            <Accordion.Panel>
                                <Accordion.Title>
                                    <span className="!text-xl text-black block py-2 px-4 font-bold">{item.name}</span>
                                </Accordion.Title>
                                <Accordion.Content className="pt-0">
                                    {item.link.map((subItem, subIndex) => (
                                        <a key={subIndex} href={subItem.sublink} className="block py-2 px-4">
                                            {subItem.subname}
                                        </a>
                                    ))}
                                </Accordion.Content>
                            </Accordion.Panel>
                        </Accordion>
                    );
                }
                return null;
            })}

        </Drawer>
    </>
  )
}

export default MobileNav
